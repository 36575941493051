import React from "react";
import {
  AbsoluteUrlLink,
  AppRoute,
  ArticleLink,
  BrandLink,
  BrandLinkTarget,
  DealerLink,
  Entity,
  LinktoUnion,
  MailtoLink,
  Maybe,
  ModelLink,
  PageLink,
  TelLink,
} from "../../hygraph/vo";
import usePathBuilder from "../../../router/hooks/usePathBuilder";
import { logger } from "../../common/scripts/logger";
import useVehicleComparisonListLink from "../../vehicle/hooks/useVehicleComparisonListLink";
import useVehicleFavoritesListLink from "../../vehicle/hooks/useVehicleFavoritesListLink";
import { HistoryState } from "../../../router/constants/State";
import { ctaOutboundLinkTrack } from "../../analytics/scripts/navigationTrack/ctaOutboundLinkTrack";
import useCurrentPage from "../../../router/hooks/useCurrentPage";
import { useLinkEntityMapContext } from "../helpers/LinkEntityMap";
import { getIsPreview } from "../../../core/previewMode";

const FALLBACK_LINK = { to: "#", disabled: true };

const buildContentButtonWarningLogger = (caller: string) => (message: string, data?: Record<string, unknown>) => {
  const ignoreContentButtonWarnings = getIsPreview();
  if (ignoreContentButtonWarnings) {
    // Only log to local console to aid editors in development
    // eslint-disable-next-line no-console
    console.warn(`ContentLink: ${caller}: ${message}.`, data);
    return;
  }
  logger.warn(`ContentLink: ${caller}: ${message}.`, data);
};

// TODO: Separate logic for generating link into separate hook,
// only use useContentButton when other button props are required
function useContentButton({
  linkEntity,
  link,
  absolute = false,
  base,
  clickTrackText,
  caller,
}: {
  linkEntity?: Maybe<Entity>;
  link?: Maybe<LinktoUnion>;
  absolute?: boolean;
  base?: string;
  clickTrackText?: string;
  caller: string;
}): {
  to: string;
  onClick?: React.MouseEventHandler | undefined;
  state?: Record<string, number | boolean> | undefined;
  target?: string;
  rel?: string;
  disabled?: boolean;
} {
  const logContentButtonWarning = buildContentButtonWarningLogger(caller);
  const {
    brandListPath,
    cockpitPath,
    dealerLocatorPath,
    vehicleEvaluationPath,
    homePath,
    magazinePath,
    savedSearchesPath,
    searchPath,
    vehicleEstimationPath,
    vehicleEvaluationIntroPath,
    tourChecklistsPath,
    brandPath,
    brandModelsPath,
    brandVehiclesPath,
    brandPromotionsPath,
    modelPath,
    staticPagePath,
    magazineArticlePath,
    matchmakerPath,
    dealerDetailPath,
    promotionsPath,
  } = usePathBuilder();
  const {
    to: comparisonPath,
    onClick: comparisonOnClick,
    state: comparisonState,
  } = useVehicleComparisonListLink({
    base,
    absolute: true,
  });
  const { to: favoritesPath, onClick: favoritesOnClick } = useVehicleFavoritesListLink({
    base,
    absolute: true,
  });
  const linkEntityMap = useLinkEntityMapContext();
  const currentPage = useCurrentPage();

  function getAppLinkTo(appRoute: AppRoute, params?: string): string | null {
    switch (appRoute) {
      case AppRoute.BrandList:
        return brandListPath({ absolute, base, params });
      case AppRoute.Cockpit:
        return cockpitPath({ absolute, base, params });
      case AppRoute.DealerLocator:
        return dealerLocatorPath({ absolute, base, params });
      case AppRoute.Favorites:
        return favoritesPath;
      case AppRoute.FfuVehicleEvaluation:
        return vehicleEvaluationPath({ absolute, base, params });
      case AppRoute.Home:
        return homePath({ absolute, base, params });
      case AppRoute.MagazineOverview:
        return magazinePath({ absolute, base, params });
      case AppRoute.SavedSearches:
        return savedSearchesPath({ absolute, base, params });
      case AppRoute.Search:
        return searchPath({ absolute, base, params });
      case AppRoute.VehicleComparison:
        return comparisonPath;
      case AppRoute.VehicleEstimation:
        return vehicleEstimationPath({ absolute, base, params });
      case AppRoute.VehicleEvaluationIntro:
        return vehicleEvaluationIntroPath({ absolute, base, params });
      case AppRoute.TourChecklist:
        return tourChecklistsPath({ absolute, base, params });
      case AppRoute.MatchMaker:
        return matchmakerPath({ absolute, base, params });
      case AppRoute.PaPromotionList:
        return promotionsPath({ absolute, base, params });
      default:
        logContentButtonWarning(`No route defined for appRoute: (${appRoute})`, { appRoute, params });
        return null;
    }
  }

  function getBrandLinkTo(brandLink: BrandLink): string | null {
    if (!brandLink.brand?.slug) {
      logContentButtonWarning("No slug defined for brand", { brand: brandLink.brand, brandLink });
      return null;
    }

    const params = {
      absolute,
      base,
      slug: brandLink.brand.slug,
    };

    switch (brandLink.target) {
      case BrandLinkTarget.Models:
        return brandModelsPath(params);
      case BrandLinkTarget.Vehicles:
        return brandVehiclesPath(params);
      case BrandLinkTarget.Promotions:
        return brandPromotionsPath(params);
      case BrandLinkTarget.Main:
      default:
        return brandPath(params);
    }
  }

  function getModelLinkTo(modelLink: ModelLink): string | null {
    if (!modelLink.model?.slug || !modelLink.model.brand?.slug) {
      logContentButtonWarning("No slug defined for model", { model: modelLink.model, modelLink });
      return null;
    }

    return modelPath({
      absolute,
      base,
      brandSlug: modelLink.model.brand.slug,
      modelSlug: modelLink.model.slug,
    });
  }

  function getPageLinkTo(pageLink: PageLink): string | null {
    if (!pageLink.page?.parent || !pageLink.page?.slug) {
      logContentButtonWarning("No slug or parent defined for page", {
        page: pageLink.page,
        pageLink,
      });
      return null;
    }

    return staticPagePath({
      absolute,
      base,
      parent: pageLink.page.parent!,
      slug: pageLink.page.slug,
    });
  }

  function getArticleLinkTo(articleLink: ArticleLink): string | null {
    if (!articleLink.article?.slug || !articleLink.article?.id) {
      logContentButtonWarning("No slug defined for article", {
        article: articleLink.article,
        articleLink,
      });
      return null;
    }

    return magazineArticlePath({
      id: articleLink.article.id,
      absolute,
      slug: articleLink.article.slug,
    });
  }

  function getDealerLinkTo(dealerLink: DealerLink): string | null {
    if (!dealerLink.dealer?.slug || !dealerLink.dealer?.insideId) {
      logContentButtonWarning("No slug or insideId defined for dealer", {
        dealer: dealerLink.dealer,
        dealerLink,
      });
      return null;
    }

    return dealerDetailPath({
      id: dealerLink.dealer.insideId,
      slug: dealerLink.dealer.slug,
      tab: dealerLink.tab,
      params: dealerLink.additionalQueryParameters ?? undefined,
    });
  }

  function getMailtoLinkTo(mailtoLink: MailtoLink): string | null {
    if (!mailtoLink?.emailAddress) {
      logContentButtonWarning("No email adress defined", { mailtoLink });
      return null;
    }

    return `mailto:${mailtoLink.emailAddress}`;
  }

  function getTelLinkTo(telLink: TelLink): string | null {
    if (!telLink?.phoneNumber) {
      logContentButtonWarning("No phone number defined", { telLink });
      return null;
    }

    return `tel:${telLink.phoneNumber}`;
  }

  function getAbsoluteUrlLinkTo(absoluteUrlLink: AbsoluteUrlLink): string | null {
    if (!absoluteUrlLink?.urlLink?.url) {
      logContentButtonWarning("No url defined", { absoluteUrlLink });
      return null;
    }

    return absoluteUrlLink.urlLink.url;
  }

  function getAppLinkOnClick(appRoute: AppRoute): React.MouseEventHandler | undefined {
    switch (appRoute) {
      case AppRoute.Favorites:
        return favoritesOnClick;
      case AppRoute.VehicleComparison:
        return comparisonOnClick;
      default:
        return undefined;
    }
  }

  function getAppLinkState(appRoute: AppRoute): Record<string, number | boolean> | undefined {
    switch (appRoute) {
      case AppRoute.VehicleEvaluationIntro:
      case AppRoute.VehicleEstimation:
      case AppRoute.FfuVehicleEvaluation:
      case AppRoute.TourChecklist:
        return { [HistoryState.ON_CLOSE_GO_N_BACK]: 1 };
      case AppRoute.VehicleComparison:
        return comparisonState;
      default:
        return undefined;
    }
  }

  function getAppLinkRel(appRoute: AppRoute): string | undefined {
    switch (appRoute) {
      case AppRoute.VehicleEvaluationIntro:
      case AppRoute.VehicleEstimation:
      case AppRoute.FfuVehicleEvaluation:
        return "nofollow";
      default:
        return undefined;
    }
  }

  function getLinkWithFallback(to: string | null, additionalData?: Omit<ReturnType<typeof useContentButton>, "to">) {
    if (!to) {
      return FALLBACK_LINK;
    }

    return {
      ...additionalData,
      to,
    };
  }

  function getLinkData(link: LinktoUnion): ReturnType<typeof useContentButton> {
    switch (link.__typename) {
      case "AppLink":
        return getLinkWithFallback(getAppLinkTo(link.appRoute, link.additionalQueryParameters || undefined), {
          onClick: getAppLinkOnClick(link.appRoute),
          state: getAppLinkState(link.appRoute),
          rel: getAppLinkRel(link.appRoute),
        });
      case "BrandLink":
        return getLinkWithFallback(getBrandLinkTo(link));
      case "ModelLink":
        return getLinkWithFallback(getModelLinkTo(link));
      case "PageLink":
        return getLinkWithFallback(getPageLinkTo(link));
      case "ArticleLink":
        return getLinkWithFallback(getArticleLinkTo(link));
      case "DealerLink":
        return getLinkWithFallback(getDealerLinkTo(link));
      case "MailtoLink":
        return getLinkWithFallback(getMailtoLinkTo(link));
      case "TelLink":
        return getLinkWithFallback(getTelLinkTo(link));
      case "AbsoluteUrlLink":
        const to = getAbsoluteUrlLinkTo(link);
        return getLinkWithFallback(to, {
          target: link.openInNewTab ? "_blank" : undefined,
          onClick: (ev: React.MouseEvent<HTMLSpanElement>) => {
            ctaOutboundLinkTrack({ ev, pageType: currentPage, clickTrackText, targetUrl: to ?? undefined });
          },
        });
      default:
        logger.error(`ContentLink: ${caller}: No link routing defined for links of type: ${link.__typename}`);
        return FALLBACK_LINK;
    }
  }

  try {
    if (link) {
      return getLinkData(link);
    }

    if (linkEntity && linkEntityMap) {
      const link = linkEntityMap[linkEntity.id];
      if (link) {
        return getLinkData(link);
      }
    }
  } catch (error) {
    const linkFromEntity = linkEntityMap && linkEntity ? linkEntityMap[linkEntity.id] : null;
    logger.error(`ContentLink: ${caller}: Error while getting link data`, { error, link, linkFromEntity });
  }

  return FALLBACK_LINK;
}

export default useContentButton;
