import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { siteSearchApi } from "../../../modules/algolia/services/siteSearchApi";
import { vehicleSearchApi } from "../../../modules/algolia/services/vehicleSearchApi";
import { liveSearchApi } from "../../../modules/algolia/services/liveSearchApi";
import { dealerSearchApi } from "../../../modules/algolia/services/dealerSearchApi";
import { geocodingApi } from "../../../modules/geo/services/geocodingApi";
import { geoLocationApi } from "../../../modules/geo/services/geoLocationApi";
import { hygraphApi } from "../../../modules/hygraph/services/hygraphApi";
import { authApi } from "../../../auth/services/authApi";
import { ffuApi } from "../../../processes/core/services/ffuApi";
import { authenticatedFfuApi } from "../../../processes/core/services/authenticatedFfuApi";
import { eflexApi } from "../../../processes/core/services/eflexApi";
import { fusionAuthApi } from "../../../auth/services/fusionAuthApi";
import { authenticatedSaveSearchApi } from "../../../save-search/services/authenticatedSaveSearchApi";
import { saveSearchApi } from "../../../save-search/services/saveSearchApi";
import { favoritesApi } from "../../../favorites/services/favoritesApi";
import { vehicleProfileApi } from "../../../processes/core/services/vehicleProfileApi";
import { tourApi } from "../../../processes/core/services/tourApi";
import { appVersionApi } from "../../../modules/layout/services/appVersionApi";
import { newsletterApi } from "../../../processes/core/services/newsletterApi";
import { authenticatedNewsletterApi } from "../../../processes/core/services/authenticatedNewsletterApi";
import authSlice from "../../../auth/authSlice";
import vehicleDetailSlice from "../../../vehicle-detail/vehicleDetailSlice";
import { comparisonApi } from "../../../comparison/services/comparisonApi";
import { dealerVehicleApi } from "../../../modules/algolia/services/dealerVehicleApi";
import { promotionVehicleApi } from "../../../modules/algolia/services/promotionVehicleApi";
import listenerMiddleware from "./middleware";
import "../../../auth/authMiddleware";

export const store = configureStore({
  reducer: combineReducers({
    auth: authSlice,
    vehicleDetail: vehicleDetailSlice,
    [appVersionApi.reducerPath]: appVersionApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [ffuApi.reducerPath]: ffuApi.reducer,
    [authenticatedFfuApi.reducerPath]: authenticatedFfuApi.reducer,
    [eflexApi.reducerPath]: eflexApi.reducer,
    [fusionAuthApi.reducerPath]: fusionAuthApi.reducer,
    [hygraphApi.reducerPath]: hygraphApi.reducer,
    [siteSearchApi.reducerPath]: siteSearchApi.reducer,
    [vehicleSearchApi.reducerPath]: vehicleSearchApi.reducer,
    [liveSearchApi.reducerPath]: liveSearchApi.reducer,
    [dealerSearchApi.reducerPath]: dealerSearchApi.reducer,
    [geocodingApi.reducerPath]: geocodingApi.reducer,
    [geoLocationApi.reducerPath]: geoLocationApi.reducer,
    [favoritesApi.reducerPath]: favoritesApi.reducer,
    [comparisonApi.reducerPath]: comparisonApi.reducer,
    [authenticatedSaveSearchApi.reducerPath]: authenticatedSaveSearchApi.reducer,
    [saveSearchApi.reducerPath]: saveSearchApi.reducer,
    [vehicleProfileApi.reducerPath]: vehicleProfileApi.reducer,
    [tourApi.reducerPath]: tourApi.reducer,
    [newsletterApi.reducerPath]: newsletterApi.reducer,
    [authenticatedNewsletterApi.reducerPath]: authenticatedNewsletterApi.reducer,
    [dealerVehicleApi.reducerPath]: dealerVehicleApi.reducer,
    [promotionVehicleApi.reducerPath]: promotionVehicleApi.reducer,
  }),
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      appVersionApi.middleware,
      authApi.middleware,
      ffuApi.middleware,
      authenticatedFfuApi.middleware,
      eflexApi.middleware,
      fusionAuthApi.middleware,
      hygraphApi.middleware,
      siteSearchApi.middleware,
      vehicleSearchApi.middleware,
      liveSearchApi.middleware,
      geocodingApi.middleware,
      geoLocationApi.middleware,
      dealerSearchApi.middleware,
      favoritesApi.middleware,
      comparisonApi.middleware,
      authenticatedSaveSearchApi.middleware,
      saveSearchApi.middleware,
      listenerMiddleware.middleware,
      vehicleProfileApi.middleware,
      tourApi.middleware,
      newsletterApi.middleware,
      authenticatedNewsletterApi.middleware,
      dealerVehicleApi.middleware,
      promotionVehicleApi.middleware,
    ),
});

export type RootState = ReturnType<typeof store.getState>;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
