import React from "react";
import { useDispatch } from "react-redux";
import { VehicleSearchResponseItem } from "../../algolia/services/vehicleSearchApi";
import useVehicleComparisonList from "../hooks/useVehicleComparisonList";
import useAlgoliaAnalytics, {
  AlgoliaAnalyticsEvent,
  buildClickedObjectIDsAfterSearchEvent,
} from "../../algolia/hooks/useAlgoliaAnalytics";
import useVehicleFavoritesList from "../hooks/useVehicleFavoritesList";
import { setVehicleDetail } from "../../../vehicle-detail/vehicleDetailSlice";
import { getUri } from "../helpers/getUri";
import { algoliaProductClickTrack } from "../../analytics/scripts/searchTrack/productClickTrack";
import { ActionItemType } from "../../algolia/constants/ActionItemType";
import { convertUnixTimestampToFormattedMonthAndYear } from "../../common/helpers/date";
import isNil from "../../common/helpers/isNil";
import { TrackingReferrer } from "../../analytics/constants/TrackingReferrer";
import CarCard from "./CarCard";

type Props = {
  vehicle: VehicleSearchResponseItem;
  aaEvent?: AlgoliaAnalyticsEvent;
  singleImage?: boolean;
  hideExtraInfo?: boolean;
  animationIndex?: number;
  page?: number;
  firstImageLoading?: "lazy" | "eager";
  trackingReferrer: TrackingReferrer;
};

const AlgoliaVehicleSearchCarCard: React.FC<Props> = ({
  vehicle,
  singleImage,
  hideExtraInfo,
  animationIndex,
  aaEvent,
  page,
  firstImageLoading,
  trackingReferrer,
}) => {
  const {
    vehicleId,
    title,
    brandName,
    dealerCity,
    dealerName,
    dealerId,
    dealerZip,
    promotionList,
    qualityChecked,
    km,
    priceB2CGross,
    pricePreparationGross,
    priceInitialB2CGross,
    leasingRateFrom,
    currency,
    slug,
    vehicleCondition,
    vehicleConditionCode,
    images,
    modelName,
    vehicleType,
    bodyType,
    transmission,
    ps,
    fuelType,
    reserved,
    firstRegistration,
    exteriorColor,
  } = vehicle;

  const dispatch = useDispatch();
  const trackEvent = useAlgoliaAnalytics();

  const uri = getUri();

  const onPointerUp = () => {
    if (aaEvent) {
      trackEvent(buildClickedObjectIDsAfterSearchEvent(aaEvent, ActionItemType.Vehicle));
    }
    algoliaProductClickTrack(vehicle, uri, trackingReferrer);
  };

  const { containsVehicle: containsComparisonVehicle, toggleVehicle: toggleComparisonListVehicle } =
    useVehicleComparisonList();
  const { containsVehicle: containsFavoriteVehicle, toggleVehicle: toggleFavoriteListVehicle } =
    useVehicleFavoritesList();

  const isInFavoritesList = containsFavoriteVehicle(`${vehicleId}`);
  const isInComparisonList = containsComparisonVehicle(`${vehicleId}`);

  // Harmonize attribute "firstRegistration" which has a different format in algolia and in hygraph
  const harmonizedFirstRegistrationDate = !isNil(firstRegistration)
    ? convertUnixTimestampToFormattedMonthAndYear(parseInt(firstRegistration))
    : undefined;

  return (
    <CarCard
      enabled
      animationIndex={animationIndex}
      brand={brandName}
      currency={currency}
      dealerCity={dealerCity}
      dealerZip={dealerZip}
      firstImageLoading={firstImageLoading}
      firstRegistration={harmonizedFirstRegistrationDate}
      hasPromotion={promotionList.length > 0}
      hideExtraInfo={hideExtraInfo}
      images={images}
      isInComparisonList={isInComparisonList}
      isInFavoritesList={isInFavoritesList}
      isQualityChecked={!isNil(qualityChecked)}
      km={km}
      leasingRateFrom={leasingRateFrom}
      model={modelName}
      priceB2CGross={priceB2CGross}
      priceInitialB2CGross={priceInitialB2CGross}
      pricePreparationGross={pricePreparationGross}
      ps={ps}
      reserved={reserved}
      singleImage={singleImage}
      slug={slug}
      title={title}
      vehicleConditionCode={vehicleConditionCode}
      vehicleId={vehicleId}
      toggleComparisonList={() => {
        toggleComparisonListVehicle(title, `${vehicleId}`);
      }}
      toggleFavoritesList={() => {
        toggleFavoriteListVehicle(title, `${vehicleId}`);
      }}
      trackingInfo={{
        exteriorColor,
        fuelType,
        transmission,
        vehicleCondition,
        vehicleType,
        bodyType,
        dealerId,
        dealerName,
        page,
        trackingReferrer,
      }}
      onPointerUp={onPointerUp}
      onPointerDown={() => {
        dispatch(setVehicleDetail(vehicle));
      }}
    />
  );
};

export default AlgoliaVehicleSearchCarCard;
