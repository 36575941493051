import { config, ExperienceId } from "./config";
import { isABTestingActive, useGetVariantCohort, VariantCohort } from "./common";

export function getABVariant<T>(variantA: T, variantB: T, variantCohort: VariantCohort): T {
  return variantCohort === "variantA" ? variantA : variantCohort === "variantB" ? variantB : variantA;
}

export function useGetABVariant<T>(experienceId: ExperienceId, variantA: T, variantB: T, fallback: T): T {
  const variantCohort = useGetVariantCohort();
  const conf = config.find(c => c.experienceId === experienceId);
  if (conf && isABTestingActive(conf)) {
    return getABVariant(variantA, variantB, variantCohort);
  } else {
    return fallback;
  }
}
