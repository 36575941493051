import i18next from "i18next";
import { useEffect, useRef, useState } from "react";
import { buildErrorNotification } from "../../notification/helpers/notificationFactory";
import useNotification from "../../notification/hooks/useNotification";
import { logger } from "../../common/scripts/logger";
import { useLocale } from "../../common/hooks/useLocale";
import useStableFunction from "../../common/hooks/useStableFunction";
import { notNil } from "../../common/helpers/isNil";

const OnDeviceSpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

const supportsOnDeviceSpeechRecognition = notNil(OnDeviceSpeechRecognition);

function useSpeechRecognition(postValue: (value: string) => void) {
  const pushNotification = useNotification();

  const [isRecording, setIsRecording] = useState(false);

  const speechRecognitionRef = useRef<SpeechRecognition | null>(null);
  const locale = useLocale();

  const defaultErrorMessage = i18next.t("GENERIC SPEECH RECOGNITION ERROR");

  const postValueStable = useStableFunction(postValue);

  useEffect(() => {
    if (!supportsOnDeviceSpeechRecognition) {
      return;
    }
    const recognition = new OnDeviceSpeechRecognition();
    speechRecognitionRef.current = recognition;

    recognition.lang = locale;

    recognition.addEventListener("start", () => {
      setIsRecording(true);
    });

    recognition.addEventListener("end", () => {
      setIsRecording(false);
    });

    recognition.addEventListener("result", event => {
      const transcript = event.results[0][0].transcript;

      postValueStable(transcript);
    });

    recognition.addEventListener("error", event => {
      const messages: { [key in SpeechRecognitionErrorCode]: string } = {
        "no-speech": i18next.t("SPEECH RECOGNITION ERROR NO SPEECH"),
        "not-allowed": i18next.t("SPEECH RECOGNITION ERROR NOT ALLOWED"),
        "service-not-allowed": i18next.t("SPEECH RECOGNITION ERROR NOT ALLOWED"),
        aborted: `${defaultErrorMessage} (${i18next.t("REASON ABORTED")})`,
        "audio-capture": `${defaultErrorMessage} (${i18next.t("REASON AUDIO CAPTURE")})`,
        network: `${defaultErrorMessage} (${i18next.t("REASON NETWORK ERROR")})`,
        "bad-grammar": `${defaultErrorMessage} (${i18next.t("REASON BAD GRAMMAR")})`,
        "language-not-supported": `${defaultErrorMessage} (${i18next.t("REASON LANGUAGE NOT SUPPORTED")})`,
      };

      const message = messages[event.error] ?? defaultErrorMessage;

      pushNotification(
        buildErrorNotification({
          icon: "mic",
          text: message,
        }),
      );

      if (event.error === "bad-grammar" || event.error === "language-not-supported") {
        logger.warn(`Speech Recognition Error: ${event.error}`);
      } else {
        // Always log these warnings as they might be of interest for debugging
        // eslint-disable-next-line no-console
        console.warn(event);
      }
    });

    return () => {
      recognition.abort();
    };
  }, [locale, postValueStable, pushNotification, defaultErrorMessage]);

  return {
    supportsSpeechRecognition: supportsOnDeviceSpeechRecognition,
    isRecording,
    startRecording: () => {
      if (isRecording) {
        speechRecognitionRef.current?.stop();
      } else {
        speechRecognitionRef.current?.start();
      }
    },
  };
}

export default useSpeechRecognition;
