export type ABConfig = {
  ab_campaign: string;
  experienceId: ExperienceId;
  variantA: string;
  variantB: string;
  fromDate: string;
  untilDate: string;
};

export const SIMILAR_VEHICLES_VDP_EXPERIENCE_ID = "CARM-956";

export type ExperienceId = typeof SIMILAR_VEHICLES_VDP_EXPERIENCE_ID;

export const config: ABConfig[] = [
  {
    ab_campaign: "Similar Vehicles VDP",
    experienceId: SIMILAR_VEHICLES_VDP_EXPERIENCE_ID,
    variantA: "Control",
    variantB: "MatchingBrand",
    fromDate: "2025-02-26",
    untilDate: "2025-03-19",
  },
];
