import isNil, { notNil } from "../../common/helpers/isNil";

const createObserverManager = () => {
  let observer: IntersectionObserver | null = null;
  const elements: Map<
    Element,
    {
      trackingCallback: () => void;
      delay: number;
      hasBeenObserved: boolean;
      timeoutId?: NodeJS.Timeout;
    }
  > = new Map();

  const initObserver = (threshold: number) => {
    if (notNil(observer)) {
      return;
    }
    observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          const element = elements.get(entry.target);
          if (isNil(element)) {
            return;
          }

          if (element.timeoutId) {
            clearTimeout(element.timeoutId);
          }

          if (!entry.isIntersecting) {
            if (element.timeoutId) {
              elements.set(entry.target, {
                ...element,
                timeoutId: undefined,
              });
            }
            return;
          }

          const setTimeoutId = setTimeout(() => {
            if (!element.hasBeenObserved) {
              elements.set(entry.target, {
                ...element,
                hasBeenObserved: true,
                timeoutId: undefined,
              });
              element.trackingCallback();
            }
          }, element.delay);

          elements.set(entry.target, {
            ...element,
            timeoutId: setTimeoutId,
          });
        });
      },
      { threshold },
    );
  };

  const observe = (element: HTMLElement, trackingCallback: () => void, threshold: number, delay: number) => {
    initObserver(threshold);

    const isNewElement = !elements.has(element);
    if (isNewElement) {
      elements.set(element, {
        trackingCallback,
        delay,
        hasBeenObserved: false,
        timeoutId: undefined,
      });
      observer?.observe(element);
    }
  };

  const disconnect = (element: HTMLElement) => {
    const elementData = elements.get(element);
    if (isNil(elementData)) {
      return;
    }

    if (elementData.timeoutId) {
      clearTimeout(elementData.timeoutId);
    }
    observer?.unobserve(element);
    elements.delete(element);
  };

  return { observe, disconnect };
};

const observerManager = createObserverManager();

export default observerManager;
