import React, { useContext } from "react";
import i18next from "i18next";
import { ArrayParam } from "use-query-params";
import SearchableSingleOptionSelect from "../../../visual-components/components/form/SearchableSingleOptionSelect";
import {
  FilterItemReferenceData,
  FilterReferenceData,
  GroupReferenceData,
} from "../../services/reference-data-aggregator/types";
import {
  BRAND_FACET_KEY,
  getBrandModelCount,
  groupSelectValuesForUrl,
  MODEL_BRAND_SEPARATOR,
  MODEL_FACET_KEY,
  urlValuesForGroupSelect,
} from "../../services/filter-types/brandModelFilterTypeHelpers";
import useFilterQueryParam from "../../hooks/useFilterQueryParam";
import { VehicleSearchResponse } from "../../../algolia/services/vehicleSearchApi";
import { getOccurrenceFor } from "../../services/getOccurrenceFor";
import { notNil } from "../../../common/helpers/isNil";
import { SelectItem } from "../../../visual-components/components/form/SingleOptionSelect";
import { useFilterStateContext } from "./FilterStateContext";
import FilterBlock from "./FilterBlock";
import GroupSelect from "./GroupSelect";
import { GroupFilterItem } from "./FilterItem";

// If the Brands Filter is "Simple", only one brand model combination can be selected
// and unavailable brands arent shown. If a brand is selected, an "all" option is shown
export const SimpleBrandsFilterContext = React.createContext(false);

const FilterBox = ({
  filterId,
  modelBrandQuery,
  addNewModelBrand,
  removeModelBrand,
  setModelBrand,
  brands,
  models: modelData,
  searchData,
  simpleBrandsFilter,
}: {
  filterId: string;
  modelBrandQuery: string;
  addNewModelBrand?: {
    label: string;
    onClick: () => void;
  };
  removeModelBrand?: () => void;
  setModelBrand: (query: string | null) => void;
  brands: FilterItemReferenceData;
  models: Record<string, GroupReferenceData>;
  searchData: VehicleSearchResponse | undefined;
  simpleBrandsFilter: boolean;
}) => {
  const queryParts = modelBrandQuery.split(MODEL_BRAND_SEPARATOR);
  const count = getBrandModelCount(queryParts);

  const brandId = queryParts[0];
  const modelIds = queryParts.slice(1);

  const hasSelectedBrand = notNil(brandId) && brandId !== "";
  const selectedBrandEntity = hasSelectedBrand
    ? {
        value: brands.map[brandId].value,
        name: brands.map[brandId].name,
      }
    : undefined;

  let filteredBrands = brands.list;
  if (simpleBrandsFilter) {
    filteredBrands = filteredBrands.filter(({ name }) => getOccurrenceFor(BRAND_FACET_KEY, name, searchData) > 0);

    if (filteredBrands.length <= 1) {
      filteredBrands = [
        { name: i18next.t("ALL"), value: "", nameNormalized: "", isBasicFilterValue: true },
        ...filteredBrands,
      ];
    }
  }

  const models = modelData[brandId]?.list || [];
  const modelsAsGroupFilter: GroupFilterItem[] = models.map(model => ({
    ...model,
    children: model.children.map(model => ({
      ...model,
      occurrence: getOccurrenceFor(MODEL_FACET_KEY, model.name, searchData),
    })),
  }));

  const handleBrandChange = ({ value }: SelectItem) => setModelBrand(value!);
  const handleModelChange = (values: string[]) => {
    setModelBrand([brandId, ...groupSelectValuesForUrl(values)].join(MODEL_BRAND_SEPARATOR));
  };

  return (
    <FilterBlock
      add={addNewModelBrand}
      className="filter__combi"
      count={count}
      filterId={filterId}
      remove={removeModelBrand}
      title={i18next.t("BRAND MODEL FILTER")}
    >
      <div className="box">
        <SearchableSingleOptionSelect
          resetOnBlur
          fallbackValue={i18next.t("ALL")}
          label={i18next.t("BRAND TITLE")}
          options={filteredBrands}
          value={selectedBrandEntity}
          onChange={handleBrandChange}
        />
        <GroupSelect
          resetOnBlur
          data={modelsAsGroupFilter}
          disabled={!hasSelectedBrand || modelsAsGroupFilter.length === 0}
          fallbackValue={i18next.t("SELECT ALL")}
          label={i18next.t("MODEL TITLE")}
          values={urlValuesForGroupSelect(modelIds)}
          onChange={handleModelChange}
        />
      </div>
    </FilterBlock>
  );
};

const MAX_BRAND_MODEL_FILTERS = 3;

const getBrandModelFilterId = (index: number): string => {
  return `brandModel-${index}`;
};

type Props = {
  queryParam: string;
  data: FilterReferenceData;
  searchData: VehicleSearchResponse | undefined;
};

const BrandModelFilter: React.FC<Props> = ({ queryParam, data, searchData }) => {
  const { setOpenFilterId } = useFilterStateContext();
  const simpleBrandsFilter = useContext(SimpleBrandsFilterContext);

  const [persistedModelBrands, setModelBrands] = useFilterQueryParam(queryParam, ArrayParam);
  const noBrandModelFilterApplied = (persistedModelBrands || []).length === 0;
  const modelBrands: string[] = noBrandModelFilterApplied ? [""] : persistedModelBrands;

  return (
    <>
      {modelBrands.map((modelBrandQuery, i) => {
        const isLastItem = i === modelBrands.length - 1;
        const hasReachedMax = modelBrands.length >= MAX_BRAND_MODEL_FILTERS;
        const canAdd = !simpleBrandsFilter && isLastItem && !hasReachedMax;

        const setModelBrand = (newModelBrandQuery: string | null) => {
          if (newModelBrandQuery === null) {
            const reducedModelBrandQuery = modelBrands.filter((_, modelBrandIndex) => modelBrandIndex !== i);
            setModelBrands(reducedModelBrandQuery);
            setOpenFilterId(getBrandModelFilterId(0));
          } else {
            const newBrands = [...modelBrands];
            newBrands[i] = newModelBrandQuery;
            setModelBrands(newBrands);
          }
        };
        const addNewModelBrand = canAdd
          ? {
              label: i18next.t("ADD BRAND MODEL FILTER"),
              onClick: () => {
                setModelBrands([...modelBrands, ""]);
                setOpenFilterId(getBrandModelFilterId(i + 1));
              },
            }
          : undefined;
        const removeModelBrand = noBrandModelFilterApplied ? undefined : () => setModelBrand(null);

        return (
          <FilterBox
            key={i}
            addNewModelBrand={addNewModelBrand}
            brands={data.brands}
            filterId={getBrandModelFilterId(i)}
            modelBrandQuery={modelBrandQuery}
            models={data.models}
            removeModelBrand={removeModelBrand}
            searchData={searchData}
            setModelBrand={setModelBrand}
            simpleBrandsFilter={simpleBrandsFilter}
          />
        );
      })}
    </>
  );
};

export default BrandModelFilter;
