import { useGetVehiclesQuery } from "../../modules/algolia/services/vehicleSearchApi";
import {
  BRAND_MODEL_OPTIONAL_FILTER,
  getAppliedFilters,
  getFilters,
} from "../../modules/vehicle/helpers/getAppliedFilters";
import { BaseVehicle } from "../../modules/vehicle/types/vehicle";
import { useGetABVariant } from "../../modules/abtesting/scripts/useGetABVariant";
import { SIMILAR_VEHICLES_VDP_EXPERIENCE_ID } from "../../modules/abtesting/scripts/config";

const SHOWN_RELATED_VEHICLES = 20;

const useGetABAppliedFilters = (
  vehicle: BaseVehicle,
  dealerRestriction: number | null,
): {
  filters: string;
  optionalFilters: string[];
} => {
  const { filters, optionalFilters } = getAppliedFilters(vehicle, dealerRestriction);

  const fallback = optionalFilters;
  const variantA = optionalFilters;
  const variantB = [...optionalFilters, ...getFilters(vehicle, BRAND_MODEL_OPTIONAL_FILTER)];
  const variantOptionalFilters = useGetABVariant<string[]>(
    SIMILAR_VEHICLES_VDP_EXPERIENCE_ID,
    variantA,
    variantB,
    fallback,
  );

  return { filters, optionalFilters: variantOptionalFilters };
};

function useRelatedVehicles(vehicle: BaseVehicle, dealerRestriction: number | null) {
  const { filters, optionalFilters } = useGetABAppliedFilters(vehicle, dealerRestriction);

  const { data: similarVehicles } = useGetVehiclesQuery({
    query: "",
    options: {
      hitsPerPage: SHOWN_RELATED_VEHICLES,
      filters,
      optionalFilters,
    },
  });

  return similarVehicles;
}

export default useRelatedVehicles;
