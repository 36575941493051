import React from "react";
import i18next from "i18next";
import { Currency, VehiclePromotion } from "../../hygraph/vo";
import useFormatter from "../../common/hooks/useFormatter";
import PopupContent from "../../visual-components/components/PopupContent";
import { ButtonLink } from "../../visual-components/components/Button";
import usePathBuilder from "../../../router/hooks/usePathBuilder";

type Props = {
  promotion: VehiclePromotion;
  currency: Currency;
};

const PromotionInfoBody: React.FC<Props> = ({ promotion, currency }) => {
  const { formatCurrency } = useFormatter();
  const priceGrossText = formatCurrency(promotion.priceGross, currency);
  const promotionInfo = promotion.paPromotion;

  const { promotionPath } = usePathBuilder();
  const linkToPromotion = promotionInfo
    ? promotionPath({ id: promotionInfo.paPromotionComponentId, slug: promotionInfo.slug })
    : null;

  return (
    <PopupContent
      gradient
      title={promotion.name ?? i18next.t("PROMOTION_one")}
      body={
        <>
          <p>{priceGrossText}</p>
          {promotionInfo ? (
            <>
              <p>{promotionInfo.description}</p>
              <p>{promotionInfo.legalLine}</p>
              {linkToPromotion ? (
                <ButtonLink to={linkToPromotion}>
                  {i18next.t("GOTO PROMOTION", { value: promotionInfo.name })}
                </ButtonLink>
              ) : null}
            </>
          ) : null}
        </>
      }
    />
  );
};

export default PromotionInfoBody;
