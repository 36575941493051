import { isDateInRange } from "../../common/helpers/date";
import { STORAGE_KEYS } from "../../common/constants/Storage";
import useLocalStorage from "../../common/hooks/useLocalStorage";
import isNil from "../../common/helpers/isNil";
import { ABConfig } from "./config";

export function isABTestingActive(config: ABConfig): boolean {
  const compareDate = new Date();
  return isDateInRange(compareDate, config.fromDate, config.untilDate);
}

export type VariantCohort = "variantA" | "variantB";

export const useGetVariantCohort = (): VariantCohort => {
  const [variantCohort, setVariantCohort] = useLocalStorage<VariantCohort | null>(STORAGE_KEYS.A_B_TESTING, null);
  if (isNil(variantCohort)) {
    const variantCohort = Math.random() < 0.5 ? "variantA" : "variantB";
    setVariantCohort(variantCohort);
    return variantCohort;
  }
  return variantCohort as VariantCohort;
};

export const getExperienceVariantString = (config: ABConfig, variant: VariantCohort) => {
  return `${config.experienceId}-${config[variant]}`;
};
