import React, { ComponentProps } from "react";
import i18next from "i18next";
import useKeyboardSelect from "../../hooks/useKeyboardSelect";
import filterDuplicatesByName from "../../../common/helpers/filterDuplicatesByName";
import SelectField from "./SelectField";
import { CustomSelectInfo, CustomSelectOption } from "./CustomSelectOptionCheckbox";

export type SelectItem<T = NonNullable<unknown>> = {
  // displayed text
  name: string;
  // internal value
  value?: string;
  // arbitrary additional data
  data?: T;
  // inactive, but not disabled
  inactive?: boolean;
  // invisible, but not disabled
  // only visible if selected
  // (via query string)
  invisible?: boolean;
};

type Props<T = NonNullable<unknown>> = {
  value: SelectItem<T> | undefined;
  showNoResultsInfo?: boolean;
  info?: string;
  options: SelectItem<T>[];
  onChange: (value: SelectItem<T>) => void;
  search?: {
    value: string;
    setValue: (val: string) => void;
    resetOnBlur?: boolean;
  };
} & Omit<ComponentProps<typeof SelectField>, "value" | "children">;

function getSelectItemLabelValue<T>(item: SelectItem<T>): SelectItem<T> {
  return { name: item.name, value: item.value ?? item.name, data: item.data, inactive: item.inactive ?? false };
}

const SingleOptionSelect = <T = NonNullable<unknown>,>({
  value: currentItem,
  search,
  options,
  onChange,
  showNoResultsInfo = false,
  info,
  ...rest
}: Props<T>) => {
  const { name, value } = currentItem ? getSelectItemLabelValue(currentItem) : { name: undefined, value: undefined };

  const uniqueOptions = filterDuplicatesByName(options, `SingleOptionSelect (${rest.label})`);
  const { onKeyDown, keyboardNavigatedItem, updateMouseHoverState } = useKeyboardSelect(uniqueOptions, onChange);

  const hasNoResults = uniqueOptions.length === 0 && showNoResultsInfo;
  return (
    <SelectField
      closeOnSelect
      search={search ? { ...search, onKeyDown } : search}
      value={name}
      onClose={() => {
        updateMouseHoverState(null);
      }}
      {...rest}
    >
      {hasNoResults ? <CustomSelectInfo label={i18next.t("NO RESULTS")} /> : null}
      {info ? <CustomSelectInfo label={info} /> : null}
      {uniqueOptions.map(item => {
        const { name: itemName, value: itemValue, inactive } = getSelectItemLabelValue(item);
        const isHovered = keyboardNavigatedItem === item;
        return (
          <CustomSelectOption
            key={itemName + itemValue}
            hovered={isHovered}
            inactive={inactive}
            label={itemName}
            selected={value === itemValue}
            onClick={() => {
              onChange(item);
            }}
            onMouseEnter={() => {
              updateMouseHoverState(item);
            }}
            onMouseLeave={() => {
              updateMouseHoverState(null);
            }}
          />
        );
      })}
    </SelectField>
  );
};

export default SingleOptionSelect;
