import { notNil } from "../../common/helpers/isNil";
import { GroupFilterItem } from "../components/filters/FilterItem";
import { AppliedTag, TagType } from "../services/filter-types/groupSelectFilterTypeHelpers";
import { AggregatedGroupFilterItem } from "../services/reference-data-aggregator/types";

const getTag = (appliedTags: AppliedTag[], value?: string, type?: TagType) =>
  appliedTags.find(({ type: tagType, value: tagValue }) => {
    return tagValue === value && (type ? tagType === type : true);
  });

const removeChildTags = (appliedTags: AppliedTag[], group?: AggregatedGroupFilterItem | GroupFilterItem) => {
  const childValues = group?.children.map(({ value }) => value) ?? [];
  return appliedTags.filter(({ type, value }) => !(type === TagType.Child && childValues.includes(value)));
};

export const addGroup = (
  items: (AggregatedGroupFilterItem | GroupFilterItem)[],
  appliedTags: AppliedTag[],
  value: string,
) => {
  if (getTag(appliedTags, value, TagType.Group)) {
    return appliedTags;
  }

  const appliedTagsWithoutChildren = removeChildTags(appliedTags, getGroup(items, value));
  return [
    ...appliedTagsWithoutChildren,
    {
      type: TagType.Group,
      value,
    },
  ];
};

export const removeGroup = (appliedTags: AppliedTag[], value?: string) => {
  return appliedTags.filter(({ type, value: appliedValue }) => !(type === TagType.Group && appliedValue === value));
};

const getGroup = (
  items: (AggregatedGroupFilterItem | GroupFilterItem)[],
  childValue: string,
): AggregatedGroupFilterItem | GroupFilterItem | undefined => {
  return items.find(({ children }) => children.some(({ value }) => value === childValue));
};

export const addChild = (
  items: (AggregatedGroupFilterItem | GroupFilterItem)[],
  appliedTags: AppliedTag[],
  value: string,
) => {
  if (getTag(appliedTags, value, TagType.Child)) {
    return appliedTags;
  }

  const group = getGroup(items, value);

  if (group && getTag(appliedTags, group?.value, TagType.Group)) {
    // Group is already added, we dont need to add the child explicitly
    return appliedTags;
  }

  const otherGroupChildren = group?.children.filter(({ value: childValue }) => childValue !== value);
  const hasSelectedAllOtherGroupChildren = otherGroupChildren?.every(({ value: childValue }) =>
    notNil(getTag(appliedTags, childValue, TagType.Child)),
  );

  if (group && otherGroupChildren && hasSelectedAllOtherGroupChildren) {
    const appliedTagsWithoutOtherGroupChildren = removeChildTags(appliedTags, group);
    return addGroup(items, appliedTagsWithoutOtherGroupChildren, group.value);
  } else {
    return [...appliedTags, { type: TagType.Child, value }];
  }
};

export const removeChild = (
  items: (AggregatedGroupFilterItem | GroupFilterItem)[],
  appliedTags: AppliedTag[],
  value: string,
) => {
  const childTag = getTag(appliedTags, value, TagType.Child);
  if (childTag) {
    return appliedTags.filter(tag => tag !== childTag);
  } else {
    const group = getGroup(items, value);
    const groupTag = getTag(appliedTags, group?.value, TagType.Group);

    if (groupTag) {
      const tagsWithoutGroup = removeGroup(appliedTags, group?.value);
      const groupRemainingChildren = group?.children.filter(({ value: childValue }) => childValue !== value) ?? [];
      const remainingChildTags = groupRemainingChildren.map(({ value }) => ({
        type: TagType.Child,
        value,
      }));

      return [...tagsWithoutGroup, ...remainingChildTags];
    } else {
      return appliedTags;
    }
  }
};
