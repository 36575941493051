import React, { ReactNode } from "react";
import { NotFoundMetaInfo } from "../../common/components/NotFound";
import { AugmentedDetailVehicle } from "../types/vehicle";
import { getSalesAdvisor } from "../helpers/getSalesAdvisor";
import usePathBuilder from "../../../router/hooks/usePathBuilder";
import RedirectMetaInfo from "../../common/components/RedirectMetaInfo";
import useRelatedVehicles from "../../../vehicle-detail/hooks/useRelatedVehicles";
import DealerInfo from "./DealerInfo";
import RelatedVehicles from "./RelatedVehicles";

type Props = {
  vehicle: AugmentedDetailVehicle;
  hero?: ReactNode;
  // fragments which are only rendered if vehicle is enabled
  detailFragments: ReactNode;
  hideDealerProfileLink?: boolean;
  dealerRestriction?: number;
};

const useModelPath = (vehicle: AugmentedDetailVehicle) => {
  const { modelPath } = usePathBuilder();

  if (vehicle?.brand?.slug && vehicle?.model?.slug) {
    return modelPath({ brandSlug: vehicle.brand.slug, modelSlug: vehicle.model.slug, absolute: true });
  }

  return null;
};

const VehicleDetailPageSkeleton: React.FC<Props> = ({
  vehicle,
  hero,
  detailFragments,
  hideDealerProfileLink,
  dealerRestriction,
}) => {
  const salesAdvisor = getSalesAdvisor(
    vehicle?.dealer?.salesAdvisorList?.salesAdvisors,
    vehicle?.brand?.insideId,
    vehicle.vehicleCondition?.salesAdvisor,
  );
  const modelPath = useModelPath(vehicle);
  const similarVehicles = useRelatedVehicles(vehicle, dealerRestriction ?? null);
  const showSimilarVehicles = similarVehicles && similarVehicles?.hits.length > 0;

  return (
    <>
      {vehicle.enabled ? null : modelPath ? (
        <RedirectMetaInfo location={modelPath} />
      ) : (
        <NotFoundMetaInfo status="410" />
      )}
      {hero}
      {vehicle.dealer ? (
        <>
          <DealerInfo
            dealer={vehicle.dealer}
            hideDealerProfileLink={hideDealerProfileLink}
            salesAdvisor={salesAdvisor}
            teaser={vehicle.teaser}
            vehicleId={vehicle.vehicleId}
            outboundTrackingVehicle={{
              vehicleId: vehicle.vehicleId,
              model: vehicle.model,
              brand: vehicle.brand,
              title: vehicle?.model?.name ?? "",
              ps: vehicle.ps,
              priceB2cGross: vehicle.priceB2cGross,
            }}
          />
        </>
      ) : null}
      {vehicle.enabled ? detailFragments : null}
      {showSimilarVehicles ? <RelatedVehicles similarVehicles={similarVehicles} vehicle={vehicle} /> : null}
    </>
  );
};

export default VehicleDetailPageSkeleton;
