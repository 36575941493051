import { dataLayer } from "../../dataLayer";
import { Currency } from "../../../hygraph/vo";
import { VehicleSearchResponseItem } from "../../../algolia/services/vehicleSearchApi";
import { BaseVehicle } from "../../../vehicle/types/vehicle";
import { DEFAULT_CURRENCY } from "../../../common/constants/Currency";
import { BaseTrackingEvent } from "../../types/BaseTrackingEvent";
import { ECommerce } from "../../types/ECommerce";
import { buildDealerName } from "../dealerTrack/buildDealerName";
import { lookupItemListName, TrackingReferrer } from "../../constants/TrackingReferrer";

export type ProductClickTrackingEvent = BaseTrackingEvent & ECommerce;

export const algoliaProductClickTrack = (
  vehicle: Partial<VehicleSearchResponseItem>,
  uri: string,
  itemListName: TrackingReferrer,
) => {
  const {
    currency,
    vehicleId,
    title,
    leasingRateFrom,
    modelName,
    priceB2CGross,
    brandName,
    vehicleCondition,
    vehicleType,
    transmission,
    fuelTypeGroup,
    bodyType,
    dealerCity,
    dealerName,
    dealerId,
    exteriorColor,
    ps,
  } = vehicle;
  dataLayer([
    {
      event: "eec_select_item",
      event_name: "select_item",
      ecommerce: {
        currency: DEFAULT_CURRENCY,
        value: priceB2CGross ?? 0,
        items: [
          {
            item_name: modelName ?? "",
            item_list_name: lookupItemListName(itemListName),
            item_id: vehicleId ?? 0,
            price: priceB2CGross ?? 0,
            quantity: 1,
            currency: currency ?? DEFAULT_CURRENCY,
            item_brand: brandName ?? "",
            item_variant: title ?? "",
            item_list_id: uri ?? "",
            price_leasing: leasingRateFrom ?? 0,
            item_category: vehicleCondition ?? "",
            item_category2: vehicleType ?? "",
            item_category3: bodyType ?? "",
            item_category4: fuelTypeGroup ?? "",
            item_category5: transmission ?? "",
            location_id: dealerCity ?? "",
            dealer: `${dealerId} ${dealerName}`,
            vehicle_color: exteriorColor ?? "",
            vehicle_ps: ps ?? 0,
          },
        ],
      },
    } satisfies ProductClickTrackingEvent,
  ]);
};

export const productClickTrack = (vehicle: BaseVehicle, uri: string, itemListName: TrackingReferrer) => {
  const {
    dealer,
    priceB2cGross,
    model,
    vehicleId,
    brand,
    vehicleCondition,
    vehicleType,
    bodyType,
    currency,
    fuelType,
    transmission,
    title,
    leasingRateFrom,
    ps,
    kw,
    exteriorColor,
  } = vehicle;
  dataLayer([
    {
      event: "eec_select_item",
      event_name: "select_item",
      ecommerce: {
        currency: currency ?? Currency.Chf,
        value: priceB2cGross ?? 0,
        items: [
          {
            item_name: model?.name ?? "",
            item_list_name: lookupItemListName(itemListName),
            item_id: vehicleId ?? 0,
            price: priceB2cGross ?? 0,
            quantity: 1,
            currency: Currency.Chf,
            item_brand: brand?.name ?? "",
            item_variant: title ?? "",
            item_list_id: uri ?? "",
            price_leasing: leasingRateFrom ?? 0,
            item_category: vehicleCondition?.name ?? "",
            item_category2: vehicleType?.name ?? "",
            item_category3: bodyType?.name ?? "",
            item_category4: fuelType?.name ?? "",
            item_category5: transmission?.name ?? "",
            location_id: dealer?.city ?? "",
            dealer: buildDealerName(dealer),
            vehicle_color: exteriorColor?.name ?? "",
            vehicle_ps: ps ?? 0,
            vehicle_kw: kw ?? 0,
          },
        ],
      },
    } satisfies ProductClickTrackingEvent,
  ]);
};
