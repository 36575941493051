import { logger } from "../scripts/logger";

export default function filterDuplicatesByName<
  T extends {
    name: string;
  },
>(list: T[], source: string): T[] {
  const uniqueValues = new Set();
  const filteredArray: T[] = [];

  for (const obj of list) {
    const value = obj.name;
    if (!uniqueValues.has(value)) {
      uniqueValues.add(value);
      filteredArray.push(obj);
    } else {
      logger.warn(`${source}: Duplicate filter option: `, obj);
    }
  }

  return filteredArray;
}
