const normalize = (string: string) =>
  string
    .trim()
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .toLowerCase();

export const localeIncludes = (string: string, searchString: string) => {
  const normalizedString = normalize(string);
  const normalizedSearchString = normalize(searchString);

  return normalizedString.includes(normalizedSearchString);
};
