import isNil from "../../../common/helpers/isNil";
import { RawBrandFilterItem } from "../vehicleFilterReferenceDataApi";
import { AggregatedFilterItem, AggregatedGroupFilterItem, GroupReferenceData } from "./types";

const modelToAggregatedFilterItem = ({
  id,
  name,
  nameNormalized,
}: RawBrandFilterItem["models"][0]): AggregatedFilterItem => ({
  value: `${id}`,
  name,
  isBasicFilterValue: false,
  nameNormalized: nameNormalized,
});

export const modelFilterItemMapper = (entries: RawBrandFilterItem[]): Record<string, GroupReferenceData> => {
  return entries.reduce<Record<string, GroupReferenceData>>((agg, brand) => {
    const childMap = brand.models.reduce<Record<string, AggregatedFilterItem>>(
      (agg, model) => ({ ...agg, [model.id]: modelToAggregatedFilterItem(model) }),
      {},
    );

    const entries = brand.models
      .filter(({ parentModel }) => isNil(parentModel?.id))
      .map(({ id }): AggregatedGroupFilterItem => {
        const modelFilterItem = childMap[id];
        const modelChildrenFilterItems = brand.models
          .filter(({ parentModel }) => id === parentModel?.id)
          .map(modelToAggregatedFilterItem);

        return {
          ...modelFilterItem,
          children: [modelFilterItem, ...modelChildrenFilterItems],
        };
      });

    agg[`${brand.id}`] = {
      list: entries,
      map: Object.fromEntries(entries.map(entry => [entry.value, entry])),
      mapByName: Object.fromEntries(entries.map(entry => [entry.name, entry])),
      childMap,
    };
    return agg;
  }, {});
};
