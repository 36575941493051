export enum SITE_FACETS {
  OBJECT_ID = "objectID",
  MODEL_ID = "modelId",
  KEYWORDS = "keywords",
}

export enum SiteModelId {
  ARTICLE = "article",
  PAGE = "page",
  PROMOTION = "papromotion",
}

export enum PROMOTION_FACETS {
  VALID_FROM = "validFromTimestamp",
  VALID_UNTIL = "validUntilTimestamp",
}
