import { createApi, retry } from "@reduxjs/toolkit/query/react";
import i18next from "i18next";
import { FALLBACK, Params } from "../../../router/constants/Params";
import { graphqlRequestBaseQuery } from "../helpers/rtk-query-graphql-request-base-query";
import { getIsPreview } from "../../../core/previewMode";

function prepareHeaders(headers: Headers): Headers {
  headers.set("gcms-locales", `${i18next.resolvedLanguage}, ${FALLBACK[Params.lang]}`);

  if (getIsPreview()) {
    headers.set("gcms-stage", "DRAFT");
  }

  return headers;
}

export const formatDateForHygraph = (date: Date): string => {
  const isoString = date.toISOString();
  // Discard time
  const dateString = isoString.split("T")[0];
  return dateString;
};

// base api for all Hygraph queries
export const hygraphApi = createApi({
  reducerPath: "hygraphApi",
  baseQuery: retry(
    graphqlRequestBaseQuery({
      url: import.meta.env.VITE_HYGRAPH_ENDPOINT!,
      prepareHeaders,
    }),
    { maxRetries: 0 },
  ),
  // use this tag to invalidate query whenever language changes
  tagTypes: ["languageDependent"],
  endpoints: () => ({}),
});
