import { isPlainObject } from "@reduxjs/toolkit";
import type { BaseQueryFn } from "@reduxjs/toolkit/query";
import type { DocumentNode } from "graphql";
import { GraphQLClient, ClientError, RequestOptions } from "graphql-request";
import type {
  ErrorResponse,
  GraphqlRequestBaseQueryArgs,
  PrepareHeaders,
  RequestHeaders,
} from "./GraphqlBaseQueryTypes";

export const graphqlRequestBaseQuery = <E = ErrorResponse>(
  options: GraphqlRequestBaseQueryArgs<E>,
): BaseQueryFn<
  { document: string | DocumentNode; variables?: any },
  unknown,
  E,
  Partial<Pick<ClientError, "request" | "response">>
> => {
  const client = "client" in options ? options.client : new GraphQLClient(options.url);
  const requestHeaders: RequestHeaders = "requestHeaders" in options ? options.requestHeaders : {};

  return async ({ document, variables }, { getState, endpoint, forced, type, signal, extra }) => {
    try {
      const prepareHeaders: PrepareHeaders = options.prepareHeaders ?? (x => x);
      const headers = new Headers(stripUndefined(requestHeaders));

      const preparedHeaders = await prepareHeaders(headers, {
        getState,
        endpoint,
        forced,
        type,
        extra,
      });

      return {
        data: await client.request({
          document,
          variables,
          signal: signal as unknown as RequestOptions["signal"],
          requestHeaders: preparedHeaders,
        }),
        meta: {},
      };
    } catch (error) {
      if (error instanceof ClientError) {
        const { name, message, stack, request, response } = error;

        const customErrors = options.customErrors ?? (() => ({ name, message, stack }));

        const customizedErrors = customErrors(error) as E;

        return { error: customizedErrors, meta: { request, response } };
      } else if (error instanceof Error) {
        const { name, message, stack } = error;
        const customizedErrors = { name, message, stack } as E;
        return { error: customizedErrors };
      } else {
        return {
          error: {
            name: "rtk-query-graphql-request-base-query: Couldn't complete request",
            message: "No error object is available",
          } as E,
        };
      }
    }
  };
};

function stripUndefined(obj: any) {
  if (!isPlainObject(obj)) {
    return obj;
  }
  const copy: Record<string, any> = { ...obj };
  for (const [k, v] of Object.entries(copy)) {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    if (typeof v === "undefined") delete copy[k];
  }
  return copy;
}
