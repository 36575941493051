import React, { ComponentProps, useState } from "react";
import classNames from "classnames";
import SelectField from "../../../visual-components/components/form/SelectField";
import CustomSelectOptionCheckbox from "../../../visual-components/components/form/CustomSelectOptionCheckbox";
import {
  AppliedTag,
  buildGroupAppliedTagList,
  mapAppliedTagsToQueryParam,
  TagType,
} from "../../services/filter-types/groupSelectFilterTypeHelpers";
import Checkbox from "../../../visual-components/components/form/Checkbox";
import filterDuplicatesByName from "../../../common/helpers/filterDuplicatesByName";
import { localeIncludes } from "../../../common/helpers/localeIncludes";
import { addChild, addGroup, removeChild, removeGroup } from "../../helpers/groupSelectHelper";
import { FilterItem, GroupFilterItem } from "./FilterItem";

type Props = {
  data: GroupFilterItem[];
  values: string[];
  onChange: (values: string[]) => void;
  resetOnBlur?: boolean;
} & Omit<ComponentProps<typeof SelectField>, "value" | "search" | "children">;

const CheckboxGroup = ({
  value,
  label,
  items,
  isSingleChild,
  checked,
  anyChildChecked,
  inactive,
  updateGroup,
  updateChild,
}: {
  value: string;
  label: string;
  checked: boolean;
  anyChildChecked: boolean;
  inactive: boolean;
  // items to display - potentially filtered by search
  items: { value: string; label: string; inactive: boolean; selected: boolean }[];
  // indicates whether the group is a single child group by default
  isSingleChild: boolean;
  updateGroup: (value: string, checked: boolean) => void;
  updateChild: (value: string, checked: boolean) => void;
}) => {
  return (
    <li key={label} aria-selected={checked} className="custom-select__option-group" role="option">
      <div className={classNames("custom-select-option-group__title", { inactive })}>
        <Checkbox
          checked={checked}
          label={label}
          subChecked={anyChildChecked}
          value={value}
          onChange={e => {
            updateGroup(value, e.target.checked);
          }}
        />
      </div>
      {items.length > 0 && !isSingleChild ? (
        <ul className="custom-select__subgroup-list">
          {items.map(({ value, label, inactive, selected }, i) => (
            <CustomSelectOptionCheckbox
              key={`${label}-${i}`}
              checked={selected || checked}
              className="custom-select-subgroup__option"
              inactive={inactive}
              label={label}
              value={value}
              onChange={(value, checked) => {
                updateChild(value, checked);
              }}
            />
          ))}
        </ul>
      ) : null}
    </li>
  );
};

type AggregatedGroupItemType = {
  value: string;
  label: string;
  selected: boolean;
  inactive: boolean;
};

type AggregatedGroupType = {
  value: string;
  label: string;
  isGroupInactive: boolean;
  isGroupSelected: boolean;
  isBasicFilterValue: boolean;
  hasSelectedChild: boolean;
  items: AggregatedGroupItemType[];
};

const GroupSelect: React.FC<Props> = ({ data, values, onChange, resetOnBlur, ...rest }) => {
  const tagList = values || [];
  const hasAppliedTags = tagList.length > 0;

  const appliedTags = buildGroupAppliedTagList(tagList);

  const queryMap = appliedTags.reduce<{ groups: Record<string, boolean>; children: Record<string, boolean> }>(
    (agg, { type, value }) => {
      if (type === TagType.Group) {
        agg.groups[value] = true;
      } else {
        agg.children[value] = true;
      }
      return agg;
    },
    { groups: {}, children: {} },
  );

  const childrenNames = data.reduce<Record<string, string>>((agg, group) => {
    group.children.forEach(child => {
      agg[child.value] = child.name;
    });
    return agg;
  }, {});

  const [searchInput, setSearchInput] = useState("");

  const aggregatedGroups = filterDuplicatesByName(
    data,
    `GroupSelect
     (${rest.label})`,
  ).map(({ value, name, isBasicFilterValue, children }, i, values): AggregatedGroupType => {
    const uniqueChildren: FilterItem[] = filterDuplicatesByName(
      children,
      `GroupSelect (${rest.label}): Children of ${name} (${value})`,
    );
    const aggregatedItems = uniqueChildren.map(
      (item: FilterItem): AggregatedGroupItemType => ({
        value: item.value,
        label: item.name,
        selected: queryMap.children[item.value],
        inactive: item.occurrence === 0 && !hasAppliedTags,
      }),
    );
    return {
      value: value,
      label: name,
      isGroupSelected: !!queryMap.groups[value],
      hasSelectedChild: aggregatedItems.some(({ selected }) => selected),
      isBasicFilterValue,
      isGroupInactive: aggregatedItems.every(({ inactive }) => inactive),
      items: aggregatedItems,
    };
  });

  const aggregatedGroupMap = aggregatedGroups.reduce<Record<string, AggregatedGroupType>>((agg, group) => {
    agg[group.value] = group;
    return agg;
  }, {});

  const filteredGroupRecords = aggregatedGroups.reduce<Record<string, AggregatedGroupType>>((agg, group) => {
    if (localeIncludes(group.label, searchInput)) {
      agg[group.value] = group;
    } else {
      const newItems = group.items.filter(({ label }) => localeIncludes(label, searchInput));
      if (newItems.length > 0) {
        agg[group.value] = {
          ...group,
          items: newItems,
        };
      }
    }

    return agg;
  }, {});

  const pushTags = (tags: AppliedTag[]) => onChange(mapAppliedTagsToQueryParam(tags));

  return (
    <SelectField
      search={{
        value: searchInput,
        setValue: setSearchInput,
        resetOnBlur,
      }}
      value={appliedTags
        .map(({ type, value }) => {
          if (type === TagType.Group) {
            return aggregatedGroupMap[value].label;
          }
          return childrenNames[value];
        })
        .join(", ")}
      {...rest}
    >
      {Object.values(filteredGroupRecords).map(item => {
        const { value, label, isGroupSelected, isGroupInactive, hasSelectedChild, items } = item;
        return (
          <CheckboxGroup
            key={label}
            anyChildChecked={hasSelectedChild}
            checked={isGroupSelected}
            inactive={isGroupInactive}
            isSingleChild={aggregatedGroupMap[value].items.length === 1}
            items={items}
            label={label}
            value={value}
            updateChild={(value, checked) => {
              if (checked) {
                pushTags(addChild(data, appliedTags, value));
              } else {
                pushTags(removeChild(data, appliedTags, value));
              }
            }}
            updateGroup={(value, checked) => {
              if (checked) {
                pushTags(addGroup(data, appliedTags, value));
              } else {
                pushTags(removeGroup(appliedTags, value));
              }
            }}
          />
        );
      })}
    </SelectField>
  );
};

export default GroupSelect;
