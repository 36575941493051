import { MutableRefObject } from "react";
import { adViewTrack } from "../scripts/adTrack/adViewTrack";
import { Page } from "../../../router/constants/Page";
import { AdType } from "../types/AdType";
import { useViewportObserver } from "./useViewportObserver";

export const useAdViewTracking = ({
  ref,
  currentPage,
  adCampaign,
  adName,
  adPosition,
  adType,
}: {
  ref: MutableRefObject<HTMLElement | null>;
  currentPage: Page | null;
  adCampaign: string;
  adName: string;
  adPosition?: number;
  adType: AdType;
}) => {
  // According to the Digital Ad Trust (IAB-Standard) https://digitaladtrust.ch/neueste-zahlen-zu-visibility-und-ad-fraud/
  const adViewThreshold = 0.5;
  const delay = 1000;

  useViewportObserver(
    ref,
    () => {
      adViewTrack({ pageType: currentPage, adCampaign, adName, adType, adPosition });
    },
    adViewThreshold,
    delay,
  );
};
