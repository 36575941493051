import { useState } from "react";
import useLocalStorage from "../../../modules/common/hooks/useLocalStorage";
import { STORAGE_KEYS } from "../../../modules/common/constants/Storage";
import { Answer, Matchmaker, QUESTION_CONFIG, QUESTION_DISPLAY_ORDER, QuestionId } from "../helpers/config";
import { GeoLocation } from "../../../modules/geo/components/GeoPopup";
import useEvent from "../../../modules/common/hooks/useEvent";

export const useMatchmaker = ({ initShowGeoPopup = false }: { initShowGeoPopup?: boolean } = {}) => {
  const [matchmaker, setMatchmaker] = useLocalStorage<Matchmaker>(STORAGE_KEYS.MATCHMAKER_ANSWERS, {} as Matchmaker);
  const [storedLocation, setStoredLocation] = useLocalStorage<GeoLocation | null>(STORAGE_KEYS.LOCATION, null);
  const { answers = {}, location = storedLocation, rememberLocation = true } = matchmaker;
  const [showGeoPopup, setShowGeoPopup] = useState<boolean>(initShowGeoPopup);

  const answeredNumberOfQuestions = Object.keys(answers).length;
  const totalNumberOfQuestions = QUESTION_DISPLAY_ORDER.length;

  const isQuestionInitialized = (questionId: QuestionId) => {
    return answers[questionId] !== undefined;
  };

  const isInitialized = Object.keys(matchmaker).length > 0;

  const getAnswerByQuestionId = (questionId: QuestionId): Answer => {
    return answers[questionId] ?? null;
  };

  const initMatchmaker = useEvent(() => {
    setMatchmaker({
      answers,
      location,
      rememberLocation,
    });
  });

  const resetMatchmaker = useEvent(() => {
    setMatchmaker({} as Matchmaker);
  });

  const updateUserAnswers = useEvent((questionId: QuestionId, answer: Answer) => {
    setMatchmaker({ ...matchmaker, answers: { ...answers, [questionId]: answer } });
  });

  const updateGeoLocation = useEvent(
    ({ geoLocation, rememberLocation }: { geoLocation: GeoLocation | null; rememberLocation?: boolean }) => {
      const newLocation = geoLocation
        ? { lat: geoLocation.lat, lng: geoLocation.lng, displayName: geoLocation.displayName }
        : null;

      // As defined in https://3.basecamp.com/3101128/buckets/25078318/todos/7024094776#__recording_7127122018
      setStoredLocation(rememberLocation ? newLocation : null);
      setMatchmaker({ ...matchmaker, location: newLocation });
    },
  );

  const setRememberLocation = useEvent((rememberLocation: boolean) => {
    setMatchmaker({ ...matchmaker, rememberLocation });
  });

  const getCountAnswerByQuestionId = (questionId: QuestionId): number => {
    const answer = getAnswerByQuestionId(questionId);
    const config = QUESTION_CONFIG[questionId];

    switch (config.questionType) {
      case "price":
        return answer ? 1 : 0;
      case "dealer":
        const optionId = answer?.[0];
        const option = config.options.find(option => option.id === optionId);

        if (option?.withLocation && location) {
          return 1;
        } else {
          return 0;
        }

      default:
        return answer?.length ?? 0;
    }
  };

  return {
    answers,
    getAnswerByQuestionId,
    location,
    answeredNumberOfQuestions,
    totalNumberOfQuestions,
    isQuestionInitialized,
    isInitialized,
    initMatchmaker,
    updateUserAnswers,
    updateGeoLocation,
    getCountAnswerByQuestionId,
    showGeoPopup,
    setShowGeoPopup,
    resetMatchmaker,
    rememberLocation,
    setRememberLocation,
  };
};
