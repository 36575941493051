import React from "react";
import i18next from "i18next";
import { FilterReferenceData } from "../../services/reference-data-aggregator/types";
import {
  brandModelsFromQuery,
  groupSelectValuesForUrl,
  MODEL_BRAND_SEPARATOR,
  urlValuesForGroupSelect,
} from "../../services/filter-types/brandModelFilterTypeHelpers";
import {
  buildGroupAppliedTagList,
  mapAppliedTagsToQueryParam,
} from "../../services/filter-types/groupSelectFilterTypeHelpers";
import { removeChild } from "../../helpers/groupSelectHelper";
import { TagRemover } from "./TagRemover";
import { FilterBlock } from "./FilterBlock";

type Props = {
  value: string[];
  queryParam: string;
  updateFilters: (q: Record<string, any>) => void;
  filterData: FilterReferenceData;
};

export const SelectedBrandModel: React.FC<Props> = ({ value, queryParam, updateFilters, filterData }) => {
  const filteredQueries = value.filter(query => !!query);
  const brandModels = filteredQueries.map(query => brandModelsFromQuery(query, filterData));

  const brandIds = brandModels.map(({ brand }) => brand.value);
  const modelIds = brandModels.flatMap(({ brand, models }) => models.map(model => `${brand.value}-${model.value}`));

  const removeModelFromQueryString = (brandId: string, modelId: string) => {
    const items = filterData.models[brandId].list;

    return {
      [queryParam]: filteredQueries.map(query => {
        // Get brandModel query and convert to regular groupSelect data
        const [brand, ...modelValues] = query.split(MODEL_BRAND_SEPARATOR);
        const appliedTagsForGroupSelect = urlValuesForGroupSelect(modelValues);
        const appliedTags = buildGroupAppliedTagList(appliedTagsForGroupSelect);

        // Remove child from groupSelect data
        const appliedTagsWithoutModel = removeChild(items, appliedTags, modelId);

        // Convert from groupSelect data to brandModel query
        const appliedTagsAsQueryParam = mapAppliedTagsToQueryParam(appliedTagsWithoutModel);
        const queryParamsForBrandModelFilter = groupSelectValuesForUrl(appliedTagsAsQueryParam);

        return [brand, ...queryParamsForBrandModelFilter].join(MODEL_BRAND_SEPARATOR);
      }),
    };
  };

  return (
    <>
      <FilterBlock label={i18next.t("BRAND TITLE")}>
        {brandIds.map((brandId, i) => {
          return (
            <TagRemover
              key={`${brandId}-${i}`}
              label={filterData.brands.map[brandId].name}
              onClick={() => {
                updateFilters({
                  [queryParam]: filteredQueries.filter(query => {
                    const { brand } = brandModelsFromQuery(query, filterData);
                    return brand.value !== brandId;
                  }),
                });
              }}
            />
          );
        })}
      </FilterBlock>
      {modelIds.length > 0 ? (
        <FilterBlock label={i18next.t("MODEL TITLE")}>
          {modelIds.map((brandModelId, i) => {
            const [brandId, modelId] = brandModelId.split("-");
            return (
              <TagRemover
                key={`${brandModelId}-${i}`}
                label={filterData.models[brandId].childMap[modelId].name}
                onClick={() => {
                  updateFilters(removeModelFromQueryString(brandId, modelId));
                }}
              />
            );
          })}
        </FilterBlock>
      ) : null}
    </>
  );
};
