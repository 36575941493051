import React, { useEffect, useRef } from "react";
import i18next from "i18next";
import { Trans } from "react-i18next";
import { BaseVehicle } from "../types/vehicle";
import { TrackingReferrer } from "../../analytics/constants/TrackingReferrer";
import useIsInViewport from "../../common/hooks/useIsInViewport";
import { VehicleSearchResponse } from "../../algolia/services/vehicleSearchApi";
import { useGetVariantCohort } from "../../abtesting/scripts/common";
import { config, SIMILAR_VEHICLES_VDP_EXPERIENCE_ID } from "../../abtesting/scripts/config";
import { handleExperienceImpressionAnalytics } from "../../abtesting/scripts/track";
import AlgoliaVehicleSearchCarCard from "./AlgoliaVehicleSearchCarCard";
import VehiclesCarousel from "./VehiclesCarousel";

type Props = {
  similarVehicles: VehicleSearchResponse;
  vehicle: BaseVehicle;
};

const RelatedVehicles: React.FC<Props> = ({ similarVehicles, vehicle }) => {
  const ref = useRef<HTMLDivElement>(null);
  const isInViewport = useIsInViewport(ref, 0.5);
  const variantCohort = useGetVariantCohort();

  useEffect(
    function handleAnalytics() {
      if (isInViewport) {
        const conf = config.find(c => c.experienceId === SIMILAR_VEHICLES_VDP_EXPERIENCE_ID);
        handleExperienceImpressionAnalytics(conf, variantCohort);
      }
    },
    [isInViewport, variantCohort],
  );

  return (
    <section ref={ref} className="section-carousel hidden-print">
      <h2 className="section-title section-carousel__title">
        <Trans i18nKey="SIMILAR VEHICLES">
          <span className="title__line-1" />
          <span className="title__line-2" />
        </Trans>
      </h2>
      <VehiclesCarousel
        resetScrollPositionTrigger={similarVehicles.params}
        title={`${i18next.t("SIMILAR VEHICLES")}: ${vehicle.brand} ${vehicle.model}`}
        totalItems={similarVehicles.hits.length}
      >
        {similarVehicles.hits.map(vehicle => (
          <AlgoliaVehicleSearchCarCard
            key={vehicle.vehicleId}
            singleImage
            trackingReferrer={TrackingReferrer.RelatedVehicles}
            vehicle={vehicle}
          />
        ))}
      </VehiclesCarousel>
    </section>
  );
};

export default RelatedVehicles;
