import React, { useRef } from "react";
import i18next from "i18next";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Button from "../../visual-components/components/Button";
import Image, { IMAGE_FIT_TYPES, IMAGE_TRANSFORM_TYPES } from "../../visual-components/components/Image";
import { Ad, AdSuccessCriteria, LinktoUnion, Maybe } from "../../hygraph/vo";
import useContentButton from "../../cms/hooks/useContentButton";
import { notNil } from "../../common/helpers/isNil";
import { useTracking } from "../../../processes/test-drive/hooks/useTracking";
import { adImpressionTrack } from "../../analytics/scripts/adTrack/adImpressionTrack";
import { adClickTrack } from "../../analytics/scripts/adTrack/adClickTrack";
import useCurrentPage from "../../../router/hooks/useCurrentPage";
import { useAdViewTracking } from "../../analytics/hooks/useAdViewTracking";
import ContextLink from "../../common/components/ContextLink";
import { usePersistAd } from "../../analytics/hooks/usePersistAd";
import { AdType } from "../../analytics/types/AdType";

type Props = {
  animationIndex?: number;
  headline?: Maybe<string>;
  imgSrc: string;
  alt: string;
  link?: Maybe<LinktoUnion>;
  loading?: "lazy" | "eager";
  pointe?: string;
  showSponsoringTag: boolean;
  subline?: Maybe<string>;
  adName: string;
  adCampaign: string;
  adPosition: number;
  adSuccessCriteria: AdSuccessCriteria[];
  adId: string;
};

const AdCard: React.FC<Props> = ({
  animationIndex,
  headline = "",
  imgSrc,
  alt,
  link,
  loading = "lazy",
  pointe = "",
  showSponsoringTag,
  subline = "",
  adName,
  adPosition,
  adCampaign,
  adSuccessCriteria,
  adId,
}) => {
  const btn = useContentButton({ link, caller: `AdCard ${adId}` });
  const text = link && "name" in link && link.name ? link.name : i18next.t("DISCOVER NOW");
  const enableAnimation = notNil(animationIndex);

  const currentPage = useCurrentPage();
  useTracking(() => adImpressionTrack({ pageType: currentPage, adCampaign, adName, adType: AdType.Tile, adPosition }));

  const ref = useRef<HTMLElement | null>(null);
  useAdViewTracking({ ref, currentPage, adName, adCampaign, adPosition, adType: AdType.Tile });
  const { handlePersist } = usePersistAd({
    campaign: adCampaign,
    successCriteria: adSuccessCriteria,
    id: adId,
    name: adName,
  } as Ad);

  return (
    <article
      ref={ref}
      style={enableAnimation ? ({ "--cm-vehicle-card-index": animationIndex } as React.CSSProperties) : undefined}
      className={classNames("card card--sponsored", {
        "card--animate-enter": enableAnimation,
      })}
      onClick={e => {
        adClickTrack({ pageType: currentPage, adCampaign, adName, adType: AdType.Tile });
        handlePersist();
        btn?.onClick?.(e);
      }}
    >
      <div className="card__carousel">
        <ContextLink className="card__carousel-wrap" {...btn}>
          <div className="card__img-wrap">
            <Image
              alt={alt}
              baseSrc={imgSrc}
              fit={IMAGE_FIT_TYPES.crop}
              imageSizes={IMAGE_TRANSFORM_TYPES.cardImage}
              loading={loading}
            />
          </div>
        </ContextLink>
        {showSponsoringTag ? <div className="card-tag">{i18next.t("SPONSORED")}</div> : null}
      </div>
      <div className="card__content">
        <Link className="card__link" to={btn.to}>
          <span className="card__body">
            <div className="card__brand">{pointe}</div>
            <h2 className="card__title">{headline}</h2>
            <div className="card__copy">{subline}</div>
          </span>
          <div className="card__footer">
            <Button {...btn}>{text}</Button>
          </div>
        </Link>
      </div>
    </article>
  );
};

export default AdCard;
