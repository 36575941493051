import i18next from "i18next";
import { FilterTypeDefinition } from "../types/filterTypes";
import { buildFilter } from "../../../algolia/services/buildFilter";
import { getNormalizedFacetValue } from "../../helpers/facetApplierHelpers";
import { BrandModelFilterDef } from "../types/filterDefinitionTypes";
import { VEHICLE_FACETS } from "../../../algolia/services/vehicleFacetKeys";
import {
  AggregatedFilterItem,
  AggregatedGroupFilterItem,
  GroupReferenceData,
} from "../reference-data-aggregator/types";
import getUniqueEntries from "../../../common/helpers/getUniqueEntries";
import { BRAND_MODEL_QUERY_PARAM } from "../filterDefinition";
import { notNil } from "../../../common/helpers/isNil";
import {
  BRAND_FACET_KEY,
  BRAND_MODEL_FACET_KEY,
  brandModelsFromQuery,
  buildBrandModelFacetFilter,
  getBrandModelCount,
  getParentModel,
  MODEL_BRAND_SEPARATOR,
  MODEL_FACET_KEY,
} from "./brandModelFilterTypeHelpers";
import { TagType } from "./groupSelectFilterTypeHelpers";

export const buildBrandModelQueryParam = (brandInsideId: number, modelInsideId?: number): Record<string, string> => {
  if (modelInsideId) {
    return {
      [BRAND_MODEL_QUERY_PARAM]: `${brandInsideId}${MODEL_BRAND_SEPARATOR}${modelInsideId}`,
    };
  }
  return {
    [BRAND_MODEL_QUERY_PARAM]: `${brandInsideId}`,
  };
};

export const brandModelFilterType: FilterTypeDefinition<BrandModelFilterDef, string[]> = {
  getConfigurationCount: (val: string[]) =>
    val
      ?.map(query => query.split(MODEL_BRAND_SEPARATOR))
      .map(queryPart => getBrandModelCount(queryPart))
      .reduce((agg, count) => agg + count, 0) ?? 0,
  paramType: "stringArray",
  getConfigurationLabels: (val, formatters, filter, aggregatedData) => {
    const filteredBrandModelQueries = val.filter(query => !!query);
    const { brand, models } = brandModelsFromQuery(filteredBrandModelQueries[0], aggregatedData);
    if (filteredBrandModelQueries.length > 1 || models.length >= 2) {
      return [i18next.t("VARIOUS VEHICLES")];
    }

    const modelName = models[0] ? models[0].name : undefined;
    return [`${brand.name}${modelName ? ` ${modelName}` : ""}`];
  },
  getSearchQueries: (param, filter, filterData) => {
    const filteredBrandModelQueries = param.filter(query => !!query);
    return [
      "(" +
        filteredBrandModelQueries
          .map(query => {
            const { brand, models } = brandModelsFromQuery(query, filterData);

            if (models.length > 0) {
              return models.map(model =>
                buildFilter(BRAND_MODEL_FACET_KEY, buildBrandModelFacetFilter(brand.name, model.name)),
              );
            }
            return buildFilter(BRAND_FACET_KEY, brand.name);
          })
          .flat()
          .join(" OR ") +
        ")",
    ];
  },
  facetMapping: {
    getKeys: () => {
      return [BRAND_FACET_KEY, MODEL_FACET_KEY];
    },
    getAppliedValues: (matchingFacetFilters, appliedQueryParams, filterData) => {
      const facetValueTuples = matchingFacetFilters.map(facetFilter => getNormalizedFacetValue(facetFilter));

      const brands = facetValueTuples.filter(
        (tuple): tuple is [VEHICLE_FACETS.BRAND, string] => tuple[0] === VEHICLE_FACETS.BRAND,
      );
      const models = facetValueTuples.filter(
        (tuple): tuple is [VEHICLE_FACETS.MODEL, string] => tuple[0] === VEHICLE_FACETS.MODEL,
      );
      // todo: filters silently remove invalid items, consider logging items?
      const mappedBrands = brands
        .map(([, normalizedName]) => {
          return filterData.brands.list.find(reference => reference.nameNormalized === normalizedName);
        })
        .filter((brand): brand is AggregatedFilterItem => !!brand);

      const getParentAndChildModels = (
        models: GroupReferenceData,
        normalizedName: string,
      ): AggregatedGroupFilterItem | AggregatedFilterItem | undefined => {
        const parentModel = models.list.find(reference => reference.nameNormalized === normalizedName);

        if (parentModel) {
          return parentModel;
        } else {
          const childModels = Object.values(models.childMap);
          return childModels.find(reference => reference.nameNormalized === normalizedName);
        }
      };
      const mappedModels = models
        .map(([, normalizedName]) => {
          // todo: also prioritize already applied brand filters
          let entries = mappedBrands
            .map(brand => [brand.value, getParentAndChildModels(filterData.models[brand.value], normalizedName)])
            .filter((tuple): tuple is [string, AggregatedGroupFilterItem | AggregatedFilterItem] => notNil(tuple[1]));
          if (entries.length === 0) {
            entries = Object.entries(filterData.models)
              .map(([brand, models]) => {
                return [brand, getParentAndChildModels(models, normalizedName)];
              })
              .filter((tuple): tuple is [string, AggregatedGroupFilterItem | AggregatedFilterItem] => notNil(tuple[1]));
          }
          return entries;
        })
        .flat()
        .filter((brand): brand is [string, AggregatedGroupFilterItem | AggregatedFilterItem] => notNil(brand));

      const mappedSingleBrands = mappedBrands.filter(({ value }) =>
        mappedModels.every(([brandValue]) => value !== brandValue),
      );

      // todo: appliedFilterMap aggregation should be extracted
      const appliedFilterMap = appliedQueryParams
        .map(query => query.split(MODEL_BRAND_SEPARATOR))
        .reduce<Record<string, string[]>>((agg, [brand, ...models]) => {
          // it's possible to add the same brand multiple times, but it's unlikely
          if (models.length > 0) {
            agg[brand] = models;
          } else {
            agg[brand] = [];
          }
          return agg;
        }, {});

      mappedSingleBrands.forEach(({ value }) => {
        appliedFilterMap[value] = [];
      });

      const getGroupValue = (value: string) => `${TagType.Group}${value}`;

      mappedModels.forEach(([brand, model]) => {
        const isParentModel = "children" in model;
        if (!appliedFilterMap[brand]) {
          appliedFilterMap[brand] = [isParentModel ? getGroupValue(model.value) : model.value];
          return;
        }

        if (isParentModel) {
          const childValues = model.children.map(({ value }) => value);
          const appliedFilters = appliedFilterMap[brand];
          const appliedFiltersWithoutChildren = appliedFilters.filter(value => !childValues.includes(value));
          appliedFilterMap[brand] = [...appliedFiltersWithoutChildren, getGroupValue(model.value)];
        } else {
          const parent = getParentModel(filterData.models[brand], model.value);
          if (parent) {
            const appliedFilters = appliedFilterMap[brand];
            if (appliedFilters.includes(getGroupValue(parent.value))) {
              return;
            }
          }

          appliedFilterMap[brand].push(model.value);
        }
      });

      return Object.entries(appliedFilterMap).map(([brand, models]) => {
        return [BRAND_MODEL_QUERY_PARAM, [brand, ...getUniqueEntries(models)].join(MODEL_BRAND_SEPARATOR)];
      });
    },
  },
};
