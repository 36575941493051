import React, { HTMLAttributeAnchorTarget, useContext, useRef } from "react";
import classNames from "classnames";
import i18next from "i18next";
import usePathBuilder from "../../../router/hooks/usePathBuilder";
import { Currency } from "../../hygraph/vo";
import useFormatter from "../../common/hooks/useFormatter";
import IconButton from "../../visual-components/components/IconButton";
import Carousel from "../../common/components/Carousel";
import OptionalLink from "../../common/components/OptionalLink";
import { getVehicleCondition } from "../helpers/getVehicleCondition";
import { notNil } from "../../common/helpers/isNil";
import { useViewportObserver } from "../../analytics/hooks/useViewportObserver";
import useCurrentPage from "../../../router/hooks/useCurrentPage";
import { batchVehiclesTrack } from "../../analytics/scripts/searchTrack/batchItems";
import { TrackingReferrer } from "../../analytics/constants/TrackingReferrer";
import { CurrentPrice, LeasingPrice, OldPrice } from "./CarPriceInfo";

type VehicleIntersectionTrackInfo = {
  exteriorColor?: string;
  fuelType?: string;
  transmission?: string;
  vehicleCondition?: string;
  vehicleType?: string;
  bodyType?: string;
  dealerId?: number;
  dealerName?: string;
  page?: number;
  trackingReferrer?: TrackingReferrer;
};

export type CarCardContextType = {
  linkBuilder: (args: { id: number; slug: string }) => string;
  enableFavorites: boolean;
  enableCompare: boolean;
  linkTarget?: HTMLAttributeAnchorTarget;
};
/**
 * Context to use custom path builder, used for whitelabel
 */
export const CarCardContext = React.createContext<CarCardContextType | null>(null);

type Props = {
  vehicleId: number;
  brand: string;
  title: string;
  km: number;
  hasPromotion: boolean;
  isQualityChecked: boolean;
  vehicleConditionCode?: string | null;
  ps?: number | null;
  dealerZip: string;
  dealerCity: string;
  priceB2CGross: number;
  pricePreparationGross?: number;
  priceInitialB2CGross?: number;
  leasingRateFrom: number;
  currency: Currency;
  images: string[];
  slug: string | null;
  trackingInfo: VehicleIntersectionTrackInfo;
  isInComparisonList: boolean;
  toggleComparisonList: () => void;
  isInFavoritesList: boolean;
  toggleFavoritesList: () => void;
  enabled: boolean;
  firstRegistration?: string;
  model?: string;
  reserved?: boolean;
  singleImage?: boolean;
  onPointerDown?: () => void;
  onPointerUp?: () => void;
  // currently only used in process context where a reduced non-interactive car card is rendered
  hideExtraInfo?: boolean;
  animationIndex?: number;
  linkTarget?: HTMLAttributeAnchorTarget;
  firstImageLoading?: "lazy" | "eager";
};

const CarCard: React.FC<Props> = ({
  vehicleId,
  brand,
  model,
  title,
  km,
  hasPromotion,
  isQualityChecked,
  vehicleConditionCode,
  ps,
  dealerZip,
  dealerCity,
  priceB2CGross,
  pricePreparationGross,
  priceInitialB2CGross,
  leasingRateFrom,
  images,
  currency,
  slug,
  isInComparisonList,
  toggleComparisonList,
  isInFavoritesList,
  toggleFavoritesList,
  enabled,
  onPointerDown,
  reserved,
  singleImage = false,
  onPointerUp,
  hideExtraInfo,
  animationIndex = undefined,
  firstRegistration,
  firstImageLoading,
  trackingInfo,
}) => {
  const { formatNumber } = useFormatter();
  const { vehicleDetailPath } = usePathBuilder();
  const carCardContext = useContext(CarCardContext);
  const vehicleDetailPathBuilder = carCardContext?.linkBuilder || vehicleDetailPath;
  const vehicleDetailPageLink = vehicleDetailPathBuilder({ slug: slug!, id: vehicleId });

  const enableFavorites = carCardContext?.enableFavorites ?? true;
  const enableCompare = carCardContext?.enableCompare ?? true;
  const linkTarget = carCardContext?.linkTarget || "_self";

  const ref = useRef<HTMLElement | null>(null);
  const pageType = useCurrentPage();
  useViewportObserver(ref, () => {
    const {
      exteriorColor,
      fuelType,
      transmission,
      vehicleCondition,
      vehicleType,
      bodyType,
      dealerId,
      dealerName,
      page,
      trackingReferrer,
    } = trackingInfo;
    batchVehiclesTrack(
      {
        exteriorColor,
        fuelType,
        transmission,
        vehicleCondition,
        vehicleType,
        bodyType,
        dealerId,
        dealerName,
        dealerCity,
        vehicleId,
        priceB2CGross,
        title,
        currency,
        brand,
        model,
        leasingRateFrom,
        ps,
        trackingReferrer,
      },
      pageType,
      page,
    );
  });

  const conditionOfVehicle = getVehicleCondition(vehicleConditionCode, firstRegistration);

  const enableAnimation = notNil(animationIndex);

  return (
    <article
      ref={ref}
      style={enableAnimation ? ({ "--cm-vehicle-card-index": animationIndex } as React.CSSProperties) : undefined}
      className={classNames("card", {
        "card--promotion": hasPromotion,
        "n-a": !enabled,
        "card--animate-enter": enableAnimation,
      })}
    >
      <Carousel
        alt={`${brand} ${title}`}
        firstImageLoading={firstImageLoading}
        images={singleImage && images.length > 0 ? [images[0]] : images}
        reserved={reserved}
        trackingTitle={title}
        link={{
          to: vehicleDetailPageLink,
          enabled: enabled,
          onPointerDown,
          onPointerUp,
          target: linkTarget,
        }}
      />
      <div className="card__content">
        <ul className="card__meta-nav-list">
          {enableFavorites ? (
            <li className="meta-nav-list__like">
              <span className="meta-nav">
                <IconButton
                  ariaLabel={i18next.t("FAVORITE")}
                  className={classNames({ liked: isInFavoritesList })}
                  icon="heart"
                  type="button"
                  onClick={() => {
                    toggleFavoritesList();
                  }}
                />
              </span>
            </li>
          ) : null}
          {enableCompare ? (
            <li className="meta-nav-list__compare">
              <span className="meta-nav">
                <IconButton
                  ariaLabel={i18next.t("COMPARE")}
                  className={classNames({ selected: isInComparisonList })}
                  icon="compare"
                  type="button"
                  onClick={toggleComparisonList}
                />
              </span>
            </li>
          ) : null}
        </ul>
        <OptionalLink
          className="card__link"
          enabled={enabled}
          target={linkTarget}
          to={vehicleDetailPageLink}
          onPointerDown={onPointerDown}
          onPointerUp={onPointerUp}
        >
          {(hasPromotion || isQualityChecked) && !hideExtraInfo ? (
            <div className="card__features">
              {hasPromotion ? <div className="promotion-tag">{i18next.t("PROMOTION", { count: 1 })}</div> : null}
              {isQualityChecked ? (
                <span
                  aria-label={i18next.t("QUALITY CHECKED")}
                  className="ifont ifont--quality_checked card__quality-badge"
                  role="img"
                />
              ) : null}
            </div>
          ) : null}
          <span className="card__body">
            <div className="card__brand">{brand}</div>
            <h2 className="card__title">{title}</h2>
            <ul className="card__data">
              {conditionOfVehicle ? <li>{conditionOfVehicle}</li> : null}
              <li>{i18next.t("KM PLACEHOLDER", { value: formatNumber(km) })}</li>
              {notNil(ps) ? <li>{i18next.t("PS PLACEHOLDER", { value: formatNumber(ps) })}</li> : null}
            </ul>
            <div className="card__location">
              <span className="ifont ifont--location_marker" />
              {dealerZip} {dealerCity}
            </div>
          </span>
          <div className="card__footer">
            {!enabled ? (
              <div className="n-a__text">{i18next.t("NOT AVAILABLE")}</div>
            ) : (
              <>
                <div className="price-wrap">
                  <div className="card__price">
                    <CurrentPrice currency={currency} priceB2cGross={priceB2CGross} />
                  </div>
                  <OldPrice
                    brand={brand}
                    currency={currency}
                    priceB2cGross={priceB2CGross}
                    priceInitialB2cGross={priceInitialB2CGross}
                    pricePreparationGross={pricePreparationGross}
                  />
                </div>
                <LeasingPrice currency={currency} leasingRateFrom={leasingRateFrom} />
              </>
            )}
          </div>
        </OptionalLink>
      </div>
    </article>
  );
};

export default CarCard;
