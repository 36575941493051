import React from "react";
import { ArrayParam } from "use-query-params";
import {
  AppliedTag,
  buildGroupAppliedTagList,
  mapAppliedTagsToQueryParam,
  TagType,
} from "../../services/filter-types/groupSelectFilterTypeHelpers";
import useFilterQueryParam from "../../hooks/useFilterQueryParam";
import filterDuplicatesByName from "../../../common/helpers/filterDuplicatesByName";
import { addGroup, removeGroup } from "../../helpers/groupSelectHelper";
import { GroupFilterItem } from "./FilterItem";
import { TagFilterBlock } from "./TagFilter";
import FilterBlock from "./FilterBlock";
import GroupSelect from "./GroupSelect";

type Props = {
  title: string;
  queryParam: string;
  data: GroupFilterItem[];
  fixOpen: boolean;
  // if renderOnlyGroups is chosen and no child element is selected, this will render a TagFilter instead
  // it also hides groups which are not basic filterValues
  renderOnlyGroups: boolean;
};

const GroupSelectFilter: React.FC<Props> = ({ title, queryParam, data, fixOpen, renderOnlyGroups }) => {
  const [persistedTags, setTags] = useFilterQueryParam(queryParam, ArrayParam);
  const tagList = persistedTags || [];
  const appliedTags = buildGroupAppliedTagList(tagList);

  const hasSelectedChildItemsExplicitly = appliedTags.some(({ type }) => type !== TagType.Group);

  const tagFilterData = filterDuplicatesByName(data, `GroupSelectFilter (${title})`)
    .map(({ value, children, ...rest }) => ({
      ...rest,
      value,
      selected: appliedTags.filter(({ value: tagValue }) => tagValue === value).length > 0,
      inactive: children.every(({ occurrence }) => occurrence === 0),
    }))
    .filter(({ selected, isBasicFilterValue }) => isBasicFilterValue || selected)
    .map(({ value, name, selected, inactive }) => ({
      value,
      name,
      selected,
      inactive,
    }));

  const pushTags = (tags: AppliedTag[]) => setTags(mapAppliedTagsToQueryParam(tags));
  const resetTags = () => setTags([]);

  if (renderOnlyGroups && !hasSelectedChildItemsExplicitly) {
    return (
      <TagFilterBlock
        count={appliedTags.length}
        data={tagFilterData}
        reset={resetTags}
        title={title}
        update={(value, selected) => {
          if (selected) {
            pushTags(addGroup(data, appliedTags, value));
          } else {
            pushTags(removeGroup(appliedTags, value));
          }
        }}
      />
    );
  }

  return (
    <FilterBlock bg className="filter__select" count={appliedTags.length} reset={resetTags} title={title}>
      <GroupSelect data={data} fixOpen={fixOpen} values={persistedTags} onChange={setTags} />
    </FilterBlock>
  );
};

export default GroupSelectFilter;
