import { VEHICLE_FACETS } from "../../../algolia/services/vehicleFacetKeys";
import { AggregatedFilterItem, FilterReferenceData, GroupReferenceData } from "../reference-data-aggregator/types";
import { GROUP_SELECT_SEPARATOR, TagType } from "./groupSelectFilterTypeHelpers";

export const MODEL_BRAND_SEPARATOR = "|";

export const BRAND_FACET_KEY = "brand";
export const MODEL_FACET_KEY = "model";
export const BRAND_MODEL_FACET_KEY = VEHICLE_FACETS.BRAND_MODEL;

export const buildBrandModelFacetFilter = (brand: string, model: string) => {
  return `${brand}${MODEL_BRAND_SEPARATOR}${model}`;
};

export const getBrandModelCount = (queryParts: string[]) => {
  let count = 0;
  const hasSelectedBrand = !!queryParts[0];
  if (queryParts.length > 1) {
    count = queryParts.length - 1;
  } else if (hasSelectedBrand) {
    count = 1;
  }
  return count;
};

export const urlValuesForGroupSelect = (values: string[]) =>
  values.map(value => {
    if (value.startsWith(TagType.Group)) {
      const id = value.substring(1);
      return [TagType.Group, id].join(GROUP_SELECT_SEPARATOR);
    } else {
      return [TagType.Child, value].join(GROUP_SELECT_SEPARATOR);
    }
  });

export const groupSelectValuesForUrl = (values: string[]) =>
  values.map(value => {
    const [type, id] = value.split(GROUP_SELECT_SEPARATOR);
    if (type === TagType.Child) {
      return id;
    } else {
      return `${TagType.Group}${id}`;
    }
  });

export const brandModelsFromQuery = (
  query: string,
  filterData: FilterReferenceData,
): { brand: AggregatedFilterItem; models: AggregatedFilterItem[] } => {
  const [brand, ...models] = query.split(MODEL_BRAND_SEPARATOR);
  const modelFilterData = filterData.models[brand];
  const modelsAsGroupSelect = urlValuesForGroupSelect(models);

  return {
    brand: filterData.brands.map[brand],
    models: modelsAsGroupSelect.flatMap(model => modelsFromQueryParam(modelFilterData, model)),
  };
};

const modelsFromQueryParam = (models: GroupReferenceData, modelQuery: string): AggregatedFilterItem[] => {
  const [tag, id] = modelQuery.split(GROUP_SELECT_SEPARATOR);
  if (tag === TagType.Group) {
    return models.map[id].children;
  } else {
    return [models.childMap[id]];
  }
};

export const getParentModel = (models: GroupReferenceData, modelId: string) =>
  models.list.find(model => model.children.some(({ value }) => value === modelId));
