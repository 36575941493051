import i18next from "i18next";
import { VehicleConditionCode } from "../../algolia/constants/VehicleConditionCode";

// Check AdDataFormatter.php for the same implementation
export const getVehicleCondition = (vehicleConditionCode?: string | null, firstRegistration?: string) => {
  if (vehicleConditionCode === VehicleConditionCode.N) {
    return i18next.t("NEW");
  }
  if (firstRegistration) {
    return firstRegistration;
  }
  return null;
};
