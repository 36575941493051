import { BaseTrackingEvent } from "../../analytics/types/BaseTrackingEvent";
import { dataLayer } from "../../analytics/dataLayer";
import { ABConfig, config } from "./config";
import { getExperienceVariantString, isABTestingActive, VariantCohort } from "./common";

type ABImpressionEvent = {
  ab_impression: {
    exp_variant_string: string;
    ab_campaign: string;
    ab_variant: string;
  };
};

export type ABImpressionTrackingEvent = BaseTrackingEvent & ABImpressionEvent;

export const handleABImpressionAnalytics = (variantCohort: VariantCohort) => {
  const analyticsData = config.filter(isABTestingActive).map(
    conf =>
      ({
        event: "gaEvent",
        event_name: "ab_impression",
        ab_impression: {
          exp_variant_string: getExperienceVariantString(conf, variantCohort),
          ab_campaign: conf.ab_campaign,
          ab_variant: conf[variantCohort],
        },
      }) satisfies ABImpressionTrackingEvent,
  );

  dataLayer(analyticsData);
};

type ExperienceImpressionEvent = {
  experience_impression: {
    exp_variant_string: string;
    ab_campaign: string;
    ab_variant: string;
  };
};

export type ExperienceImpressionTrackingEvent = BaseTrackingEvent & ExperienceImpressionEvent;

export const handleExperienceImpressionAnalytics = (config: ABConfig | undefined, variantCohort: VariantCohort) => {
  if (config && isABTestingActive(config)) {
    dataLayer([
      {
        event: "gaEvent",
        event_name: "experience_impression",
        experience_impression: {
          exp_variant_string: getExperienceVariantString(config, variantCohort),
          ab_campaign: config.ab_campaign,
          ab_variant: config[variantCohort],
        },
      } satisfies ExperienceImpressionTrackingEvent,
    ]);
  }
};
